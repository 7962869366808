import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlogMeta } from "../../components/blogMeta"

export default function BlogPostWebsiteImagesForSEOCRO() {
  return (
    <Layout>
      <SEO title="Website Images for SEO and CRO" />
      <main>
        <h1>Website Images for SEO and CRO</h1>
        <BlogMeta
          author="Primespot Marketing"
          published="May 20, 2020"
          tags={["Marketing"]}
        />
        <p>Does your website have any images on it?</p>
        <p>I thought so.</p>
        <p>
          A website without images is extremely rare. And for good reason.
          Images provide a ton of value.
        </p>
        <p>
          They improve website design (unless used poorly). They make a website
          look more colorful and lively. They can have meaning if they’re
          relevant.
        </p>
        <p>
          Images can also help boost SEO (search engine optimization) and CRO
          (conversion rate optimization).
        </p>
        <p>
          And here is some more good news. Optimizing images for SEO and CRO is
          easy!
        </p>
        <p>
          I’m going to provide a checklist of sorts for how to optimize your
          image. The list won’t be comprehensive, but it will cover the most
          important steps. Don’t skip a single one, or you could significantly
          hinder your results.
        </p>
        <p>
          But first I need to talk about image selection. This is probably the
          hardest part of the process.
        </p>
        <p>
          Whatever images you select need to be relevant to the content. Don’t
          just pick any stock photo off the internet just so you have a picture
          on your home page. If possible, use a photo relevant to your business
          that you have taken yourself.
        </p>
        <p>
          If that doesn’t work, you can scour the internet for a free (or paid)
          option, but it does need to be relevant to the content of the page.
        </p>
        <p>And now---as promised--the checklist.</p>
        <ul>
          <li>
            Name the file based on your content. Don’t use the random names that
            appear when you snap a photo on your phone or download a photo from
            the internet. Make the file name relevant to the image, the content
            of the page, and your website.
          </li>
          <li>
            Choose the right file type. If the image isn’t the appropriate file
            type, use a tool such as{" "}
            <a href="https://squoosh.app">https://squoosh.app/</a> to convert
            it. Most of the time, you want the file type to be JPEG. If the
            image needs a transparent background, you will need it to be a PNG.
            Google image file types to learn more about this subject. It can be
            nuanced.
          </li>
          <li>
            If the image is larger than it needs to be, resize it manually
            before using it. This will significantly reduce the file size of the
            image. By doing so, you will speed up your website. Not only is this
            great for your website visitors, but it is also great for SEO.
          </li>
          <li>
            Add the alt attribute to your image. Screen readers will read this
            text aloud in place of the image. It’s also a great place for an SEO
            plug. Be sure that the alt text reflects the content of the page or
            section that the image belongs.
          </li>
          <li>
            Add a caption BELOW the image. This caption text is a great place
            for important messages. Caption text is frequently read. Because of
            this, you want to be on your A game when you decide what the caption
            text should say. It should likely be something that can help lead to
            a sale.
          </li>
        </ul>
        <p>
          It’s as simple as that. With just these few tips, you can handle about
          80% of what you need to as far as images are concerned.
        </p>
        <p>
          Sure, there are additional optimizations that can be done such as
          using the srcset attribute, further reducing image file size, and
          using CDNs for increased performance. But these optimizations can wait
          until after you handle the basics.
        </p>
        <p>
          I know that some of these tips are a bit technical and not everybody
          will know how to follow them. But they are crucial.
        </p>
        <p>
          If you have any trouble with any of the steps or need further help,
          email me at{" "}
          <a href="mailto:michael@primespot.co">michael@primespot.co</a> and
          I’ll see what I can do.
        </p>
      </main>
    </Layout>
  )
}
